import { Component } from '@angular/core';
import { ActivatedRoute, Routes } from '@angular/router';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrl: './my-account.component.css'
})
export class MyAccountComponent {
  
  public templateVars: { title: string, subtitle: string } = {
    title: 'My Account',
    subtitle: 'Please ensure your address details (including state & postal code) are correct before making your purchase.'
  }

  public childRoutes: Routes = [
    // PERSONAL DATA
    {
      title: 'Personal Data',
      path:  'personal-data',
    },
    // MY TICKETS
    {
      title: 'My Tickets',
      path: 'my-tickets',	
    },
    // ORDER HISTORY
    {
      title: 'Transaction History',
      path: 'transaction-history',
    }
  ];

}
